import styles from './Footer.module.css';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <div className={styles.links}>
        <a
          href="https://support.cpm.org/support/tickets/new"
          target="_blank"
          rel="noreferrer noopener"
        >
          HELP DESK
        </a>
        <a
          href="https://cpm.org/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
        >
          PRIVACY POLICY
        </a>
      </div>
      <div className={styles.copyright}>
        © 1989-{currentYear} CPM EDUCATIONAL PROGRAM. All rights reserved. CPM
        Educational Program is a 501(c)(3) educational nonprofit corporation.
      </div>
    </div>
  );
};
