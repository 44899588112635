import { useState } from 'react';
import { UserLinks } from './UserLinks';
import { links } from '../../utils/links';
import styles from './RegistrationWizard.module.css';

export const RegistrationWizard = () => {
  const [isEducator, setIsEducator] = useState(false);

  if (isEducator) {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.title}>I would like to:</div>
          <UserLinks links={links[2]} />
          <button className={styles.back} onClick={() => setIsEducator(false)}>
            GO BACK
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>I am a(n):</div>
        <UserLinks links={links[1]} handleOnClick={setIsEducator} />
      </div>
    </>
  );
};
