let ACTIVATE_EBOOKS_PIN_URL;
let PARENT_URL;
let SHOP_URL;
let PL_REGISTER_URL;

if (window.location.hostname === 'accountregister.cpm.org') {
  ACTIVATE_EBOOKS_PIN_URL = 'https://enroll.cpm.org';
  PARENT_URL =
    'https://sso.cpm.org/wspregister?requirePasswordChangeOnLogin=false';
  SHOP_URL = 'https://shop.cpm.org/content/create-account.asp';
  PL_REGISTER_URL = 'https://sso.cpm.org/workshopregister';
} else {
  ACTIVATE_EBOOKS_PIN_URL = 'https://enroll-test.cpm.org';
  PARENT_URL =
    'https://sso-test.cpm.org/wspregister?requirePasswordChangeOnLogin=false';
  SHOP_URL = 'https://shop.cpm.org/content/create-account.asp';
  PL_REGISTER_URL = 'https://sso-test.cpm.org/workshopregister';
}

export const links = {
  1: [
    { name: 'Student', url: ACTIVATE_EBOOKS_PIN_URL },
    {
      name: 'Parent',
      url: PARENT_URL,
    },
    { name: 'Educator', url: null },
    {
      name: 'Purchasing Agent',
      url: SHOP_URL,
    },
  ],
  2: [
    { name: 'Activate eBooks with a pin', url: ACTIVATE_EBOOKS_PIN_URL },
    {
      name: 'Purchase materials',
      url: SHOP_URL,
    },
    {
      name: 'Register for Professional Learning',
      url: PL_REGISTER_URL,
    },
  ],
};
