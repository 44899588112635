import { ReactComponent as CalendarBlankIcon } from '../assets/CalendarBlank.svg';
import { ReactComponent as ChalkboardTeacherIcon } from '../assets/ChalkboardTeacher.svg';
import { ReactComponent as CheckCircleIcon } from '../assets/CheckCircle.svg';
import { ReactComponent as ShoppingCartIcon } from '../assets/ShoppingCart.svg';
import { ReactComponent as StudentIcon } from '../assets/Student.svg';
import { ReactComponent as UserCircleIcon } from '../assets/UserCircle.svg';
import { ReactComponent as UserListIcon } from '../assets/UserList.svg';

export const getIconByLinkName = (name: string) => {
  switch (name) {
    case 'Student':
      return <StudentIcon />;
    case 'Parent':
      return <UserCircleIcon />;
    case 'Educator':
      return <ChalkboardTeacherIcon />;
    case 'Purchasing Agent':
      return <UserListIcon />;
    case 'Activate eBooks with a pin':
      return <CheckCircleIcon />;
    case 'Purchase materials':
      return <ShoppingCartIcon />;
    case 'Register for Professional Learning':
      return <CalendarBlankIcon />;
  }
};
