import { RegistrationWizard } from './components/RegistrationWizard';
import { ReactComponent as CPMCompactLogo } from './assets/CPMCompact.svg';
import { Footer } from './components/Footer';
import styles from './App.module.css';

export const App = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <CPMCompactLogo />
      </div>
      <RegistrationWizard />
      <Footer />
    </div>
  );
};

export default App;
