import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { ReactComponent as CaretRightIcon } from '../../assets/CaretRight.svg';
import { getIconByLinkName } from '../../utils/getIconByLinkName';
import styles from './RegistrationWizard.module.css';

export interface UserLinkProps {
  handleOnClick?: Dispatch<SetStateAction<boolean>> | undefined;
  name: string;
  url: string | null;
}

export const UserLink = ({ handleOnClick, name, url }: UserLinkProps) => {
  const [showCaret, setShowCaret] = useState(false);

  if (!url && handleOnClick) {
    return (
      <button
        className={styles.buttonLink}
        onClick={() => handleOnClick((prevState) => !prevState)}
        onMouseEnter={() => setShowCaret(true)}
        onMouseLeave={() => setShowCaret(false)}
      >
        <div className={styles.linkContent}>
          {getIconByLinkName(name)}
          <span className={styles.linkName}>{name}</span>
        </div>
        <div className={styles.flex}>{showCaret && <CaretRightIcon />}</div>
      </button>
    );
  }

  return (
    <a
      className={styles.link}
      href={url as string}
      onMouseEnter={() => setShowCaret(true)}
      onMouseLeave={() => setShowCaret(false)}
    >
      <div className={styles.linkContent}>
        {getIconByLinkName(name)}
        <span className={styles.linkName}>{name}</span>
      </div>
      <div className={styles.flex}>{showCaret && <CaretRightIcon />}</div>
    </a>
  );
};

export interface UserLinksProps {
  handleOnClick?: Dispatch<SetStateAction<boolean>>;
  links: ({ name: string; url: string } | { name: string; url: null })[];
}

export const UserLinks = ({ handleOnClick, links }: UserLinksProps) => {
  return (
    <div className={styles.links}>
      {links.map(({ name, url }, index) => (
        <Fragment key={name}>
          <UserLink handleOnClick={handleOnClick} name={name} url={url} />
          {index !== links.length - 1 && <div className={styles.divider} />}
        </Fragment>
      ))}
    </div>
  );
};
